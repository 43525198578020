export default {
    // modules
    ambienttemperatures: '',
    applications: '',
    articles: 'mdi-router-network-wireless',
    articleitems: '',
    assets: 'mdi-file-tree-outline',
    brands: 'mdi-creative-commons',
    characteristics: '',
    classifications: '',
    climate: '',
    companies: 'mdi-office-building',
    contacts: 'mdi-contacts-outline',
    conformitymarks: 'mdi-marker-check',
    damagechances: '',
    dashboard: 'mdi-monitor-dashboard',
    defects: 'mdi-file-document-alert',
    distributionpanels: 'mdi-light-switch-off',
    distributiondevicefunctions: 'mdi-monitor-dashboard',
    documents: 'mdi-file-document',
    documentation: 'mdi-bookshelf',
    employees: 'mdi-account',
    emergencies: '',
    equipments: 'mdi-ticket-confirmation-outline',
    equipmentgroups: '',
    equipmentprotectionlevels: '',
    equipmentcategories: '',
    equipmenttypes: 'mdi-ticket-confirmation-outline',
    environments: '',
    employeeexpertise: '',
    explosiveatmospheres: '',
    fields: 'mdi-format-letter-matches',
    fieldsets: 'mdi-view-list-outline',
    forms: 'mdi-file-table-outline',
    gasgroups: '',
    home: 'mdi-home',
    importassets: 'mdi-upload',
    impactloads: '',
    intensity: '',
    inspectioninstitutes: 'mdi-office-building',
    inspectioninstitutecertifications: 'mdi-city',
    inspectioncodes: 'mdi-ab-testing',
    inspectioncertificates: 'mdi-certificate',
    inspectiondelays: '',
    inspectionclasses: '',
    inspections: 'mdi-account-hard-hat',
    inspectionitems: '',
    inspectionsconfig: 'mdi-cog',
    iptypes: 'mdi-cable-data',
    ipindications: 'mdi-cable-data',
    linevoltage: 'mdi-flash-triangle-outline',
    locations: '',
    markering: '',
    materials: '',
    notes: 'mdi-note',
    people: 'mdi-human-male-female',
    permissions: 'mdi-security',
    planner: 'mdi-calendar',
    platformmanagement: 'mdi-card-account-phone-outline',
    powersystems: '',
    projects: 'mdi-account-hard-hat',
    projectmanagement: 'mdi-account-hard-hat',
    projectmanagementquestions: 'mdi-file-table-outline',
    profile: 'mdi-account',
    protectionkinds: '',
    protectiontypes: '',
    protectionmethods: 'mdi-shield-check-outline',
    punchlists: 'mdi-format-list-bulleted',
    punchlistitems: 'mdi-format-list-bulleted',
    questionnaires: 'mdi-file-table-outline',
    questionnairequestions: 'mdi-help-circle-outline',
    questioncategories: 'mdi-format-list-bulleted',
    regulations: '',
    reportsections: 'mdi-view-list-outline',
    roles: 'mdi-account-group',
    rooms: '',
    search: 'mdi-folder-search',
    settings: 'mdi-cogs',
    stof: '',
    substances: '',
    tags: 'mdi-tag',
    templates: 'mdi-file-document-edit',
    temperatureclasses: '',
    tokens: 'mdi-account-key',
    translations: 'mdi-translate',
    typesandsubtypes: 'mdi-file-document-edit',
    usagefrequency: '',
    users: 'mdi-account',
    vibrations: '',
    water: '',
    workflows: 'mdi-transit-connection-variant',
    workequipmentgroups: '',
}