<template>
  <v-expansion-panel v-if="showPanel">
    <v-expansion-panel-title>
      <v-toolbar flat color="white" dense style="width:98%">
        <v-icon v-if="$icons[relation_name]" class="mr-2">{{ $icons[relation_name] }}</v-icon>
        <v-toolbar-title v-if="label">{{ $store.getters.translate(label) }}<small class="ml-1">({{ getRecords.length }})</small></v-toolbar-title>
        <v-toolbar-title v-else>{{ $store.getters.translate(relation_name) }}<small class="ml-1">({{ getRecords.length }})</small></v-toolbar-title>
        <v-spacer/>
        <!--PUNCHLIST ITEMS FILTER BUTTONS-->
        <v-switch v-if="relation_name === 'punchlistitems'" color="primary" class="mt-5" :label="$store.getters.translate('hide_inactive')" v-model="options.hide_inactive_punchlistitems" @click.stop=""/>
        <v-switch v-if="relation_name === 'punchlistitems'" color="primary" class="ml-10 mt-5" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_punchlistitems" @click.stop=""/>
        <!--DEFECTS FILTER BUTTONS-->
        <v-switch v-if="relation_name === 'defects'" color="primary" class="mt-5" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_defects" @click.stop=""/>
        <!--INSPECTION ITEMS FILTER BUTTONS-->
        <v-switch v-if="relation_name === 'inspectionitems'" color="primary" class="mt-5" :label="$store.getters.translate('hide_closed')" v-model="options.hide_closed_inspectionitems" @click.stop=""/>
        <!--RIGHT PART START (BUTTONS)-->
        <v-spacer/>
        <!--EXCEL EXPORT-->
        <download-excel v-if="getRecords.length > 0 && relation_name !== 'punchlistitems'" style="font-size: 12px; text-transform: uppercase; cursor: pointer;" :fields="export_fields" :data="getRecordsForExport" :name="relation_name + '.xls'">
          {{ $store.getters.translate("export_to_excel") }}
          <v-icon color="primary" size="large" class="ml-2" icon="mdi-file-excel"/>
        </download-excel>
        <!--LINK-->
        <base-link
            v-if="base_table_includable_options.link && selected.length === 0 && canEditBaseModel"
            :label="$store.getters.translate('link') + ' ' + $store.getters.translate(relation_name)"
            @refresh="$emit('refresh')"
            @change="linkOrCreateRecord"
            :module_name="relation_name"
            :excluded_ids="$lodash.map(getRecords, 'id')"/>
        <!--DELETE OR DETACH-->
        <template v-if="selected.length > 0">
          <v-btn :disabled="loading" v-if="base_table_includable_options.detachable && canEditBaseModel" @click.stop="bulkArchiveOrDetach" size="small" color="red">
            <span>{{ $store.getters.translate("detach") + ' ' + selected.length + ' ' + $store.getters.translate(relation_name) }}</span>
            <v-icon color="red" size="large" class="ml-2" icon="mdi-link-variant"/>
          </v-btn>
          <v-btn :disabled="loading" v-else-if="!base_table_includable_options.detachable && canDeleteRelatedModel" @click.stop="bulkArchiveOrDetach" size="small" color="red">
            <span>{{ $store.getters.translate("delete") + ' ' + selected.length + ' ' + $store.getters.translate(relation_name) }}</span>
            <v-icon color="red" size="large" class="ml-2" icon="mdi-link-variant"/>
          </v-btn>
        </template>
        <!--DOWNLOAD DOCUMENTS-->
        <v-btn :disabled="loading" v-if="relation_name === 'documents' && selected.length > 0" @click.stop="$emit('download', selected);" size="small">
          {{ $store.getters.translate("download") }}
          <v-icon color="primary" size="large" class="ml-2" icon="mdi-download"/>
        </v-btn>
        <!--CREATE-->
        <v-btn :disabled="loading" v-if="base_table_includable_options.create && canCreateRelatedModel" @click.stop="$emit('create');" size="small">
          {{ $store.getters.translate("create") }}
          <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
        </v-btn>
        <!--FILTER-->
        <v-btn :disabled="loading" color="primary" @click.stop="$emit('showFieldsSettings', relation_name);" variant="text" size="small">
          <v-icon size="x-large" icon="mdi-filter-variant"/>
        </v-btn>
      </v-toolbar>
    </v-expansion-panel-title>
    <v-expansion-panel-text>
      <v-data-table
          :headers="headers"
          :items="getRecords"
          :loading="loading"
          class="elevation-1"
          item-value="id"
          selectable-key="id"
          return-object
          :show-select="!base_table_includable_options.no_select"
          v-model="selected"
          v-bind="footer_props"
          v-model:items-per-page="options.itemsPerPage"
          v-model:page="options.page"
          v-model:sort-by="options.sortBy"
          @update:current-items="current = $event"
          :custom-sort="customSort"
          :search="search">
        <template #top>
          <v-text-field
              color="primary"
              variant="outlined"
              density="compact"
              v-if="base_table_includable_options.show_search"
              v-model="search"
              @click:clear="clearSearch"
              :label="$store.getters.translate('search')"
              single-line
              hide-details
              clearable
              append-inner-icon="mdi-magnify"/>
        </template>
        <template #body="{ items }">
          <tr style="height: 40px;" v-for="item in items" :key="item.id" @click.ctrl="handleCtrlClick(item)">
            <td style="text-align: center; height: 40px;" v-if="!base_table_includable_options.no_select">
              <v-checkbox style="height: 40px;" color="primary" density="compact" v-model="selected" :value="item" @click.shift="handleShiftClick(item)"/>
            </td>
            <td style="height: 40px;" v-for="(key, index) in Object.keys(response_fields)" :key="index" @click.exact="$emit('edit', item, relation_name)">
              <!--CHECKBOXES/BOOLEAN VALUES-->
              <template v-if="response_fields[key] === 'boolean'">
                <!--CUSTOM FIX FOR PUNCHLIST ITEMS-->
                <template v-if="relation_name === 'punchlistitems'">
                  <!--CUSTOM FIX TO REVERT OPEN/CLOSED STATUS-->
                  <template v-if="key === 'status' && item[key]">Closed</template>
                  <template v-else-if="key === 'status'">Open</template>
                  <template v-else-if="key === 'technical_authority_required' && item[key]">Yes</template>
                  <template v-else-if="key === 'technical_authority_required'">No</template>
                  <template v-else-if="key === 'active' && item[key]">Accepted</template>
                  <template v-else-if="key === 'active'">Draft</template>
                  <template v-else-if="key === 'rejected' && item[key]">Yes</template>
                  <template v-else-if="key === 'rejected'">No</template>
                  <v-icon v-else-if="item[key]" color="primary" size="large" icon="mdi-check-all"/>
                  <v-icon v-else color="red" size="large" icon="mdi-close"/>
                </template>
                <!--OTHER MODELS-->
                <v-icon v-else-if="item[key]" color="primary" size="large" icon="mdi-check-all"/>
                <v-icon v-else color="red" size="large" icon="mdi-close"/>
              </template>
              <!--DATES-->
              <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
              </template>
              <!--DROPDOWNS-->
              <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                <template v-if="Array.isArray(item[key])">
                  <template v-if="item[key].length > 1">
                    <v-chip style="height: auto;" class="mr-2" size="x-small" v-for="elem in item[key]" :key="elem">
                      {{ elem }}
                    </v-chip>
                  </template>
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                  </template>
                </template>
                <template v-else>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                </template>
              </template>
              <!--LINKS-->
              <template v-else-if="response_fields[key] === 'link'">
                <a>
                  <v-avatar v-if="key === 'name' && item.person" class="mr-2" size="20">
                    <v-img style="border: 1px" :src="item.person.avatar ? item.person.avatar : '../../../assets/images/no-user.jpg'"/>
                  </v-avatar>
                  {{ $lodash.truncate($store.getters.translate(item[key]), { length: 50 }) }}
                </a>
              </template>
              <!--ID FIELDS-->
              <template v-else-if="key === 'id' || key === 'internal_id'">
                <a>{{ item[key] }}</a>
              </template>
              <!--TEXT FIELDS-->
              <template v-else>
                {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
              </template>
            </td>
            <!--ACTION BUTTONS-->
            <td style="text-align: center; height: 40px;" v-if="!preview && ((base_table_includable_options.copy && canCreateRelatedModel) || canEditRelatedModel)">
              <v-icon v-if="base_table_includable_options.copy && canCreateRelatedModel" @click="makeCopy(item)" size="small" class="mr-2" icon="mdi-content-copy"/>
              <v-icon v-if="canEditRelatedModel" @click="$emit('edit', item, relation_name)" size="small" class="mr-2" icon="mdi-pencil"/>
            </td>
          </tr>
          <tr v-if="getRecords.length === 0">
            <td class="text-center text-grey" :colspan="headers.length + 1">
              <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
              <span v-else>{{ $store.getters.translate("loading") }}</span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<script>
import BaseLink from "./BaseLink";
import moment from "moment";
import helpFunctions from "../../plugins/helpFunctions.js";
import { useAbility } from '@casl/vue';
import lodash from "lodash";

export default {
  components: {
    BaseLink,
  },
  setup() {
    const { can } = new useAbility();
    return { can }
  },
  props: ["page", "parent_module_id", "top_level_module_type", "top_level_module_id", "relation_name", "label", "preview"],
  data() {
    return {
      loading: false,
      selected: [],
      records: [],
      search: "",
      options: {},
      footer_props: helpFunctions.footer_props,
      base_table_includable_options: {},
      headers: [],
      response_fields: [],
      current: [],
      export_fields: {},
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.relation_name + "_includable_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.relation_name + "_includable_options");
    }
    else {
      this.options = helpFunctions.table_options;
    }
    //DISABLE ALL FUNCTIONALITY IF THIS IS PREVIEW
    if(this.preview) {
      this.base_table_includable_options = {
        create: false,
        link: false,
        show_search: false,
        no_select: true,
      };
    }
    else {
      this.base_table_includable_options = helpFunctions.base_table_includable_options[this.page.name][this.relation_name];
    }
    this.getFields();
  },
  methods: {
    bulkArchiveOrDetach() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.loading = true;
          var result = {
            model_id: this.parent_module_id,
            relation_name: this.relation_name,
            model_to_delete: helpFunctions.models[this.relation_name],
            ids: this.$lodash.map(this.selected, "id"),
          };
          if(this.page.model === 'employee' && (this.relation_name === 'roles' || this.relation_name === 'tokens')) {
            result.model = 'User';
          }
          else {
            result.model = this.page.model;
          }
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/remove", result)
              .then(() => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("success"));
                this.selected = [];
                this.$emit("update");
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.response?.data?.error ?? error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    linkOrCreateRecord(objects) {
      this.loading = true;
      var result = {
        model_id: this.parent_module_id,
        relation_name: this.relation_name,
        ids: this.$lodash.map(objects, "id"),
        objects: objects,
      };
      if(this.page.model === 'employee' && (this.relation_name === 'roles' || this.relation_name === 'tokens')) {
        result.model = 'User';
      }
      else {
        result.model = this.page.model;
      }
      this.$http
          .post(this.$store.getters.appUrl + "v2/bulk/attach", result)
          .then(() => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("success"));
            this.$emit("update");
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.response?.data?.error ?? error.message);
            }
            this.loading = false;
          });
    },
    makeCopy(item) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
        if (res) {
          this.loading = true;
          item.model = helpFunctions.models[this.relation_name];
          this.$http
              .post(this.$store.getters.appUrl + "v2/bulk/copy", item)
              .then((response) => {
                this.loading = false;
                this.$toast.success(this.$store.getters.translate("success"));
                this.selected = [];
                this.$emit("update");
                this.$emit('edit', response.data, this.relation_name);
              }).catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toast.error(error.response?.data?.error ?? error.message);
                }
                this.loading = false;
              });
        }
      });
    },
    clearSearch() {
      this.search = "";
    },
    customSort(items, index, isDesc) {
      let field = index[0];
      let desc = isDesc[0];
      let result = this.$lodash.sortBy(items, field);
      if (field === "date_of_birth") {
        result = result.sort((a, b) => {
          a = moment(a["date_of_birth"], "DD-MM-YYYY").toDate();
          b = moment(b["date_of_birth"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (field === "date_punched") {
        result = result.sort((a, b) => {
          a = moment(a["date_punched"], "DD-MM-YYYY").toDate();
          b = moment(b["date_punched"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (field === "date_cleared") {
        result = result.sort((a, b) => {
          a = moment(a["date_cleared"], "DD-MM-YYYY").toDate();
          b = moment(b["date_cleared"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (desc) {
        return result.reverse();
      }
      return result;
    },
    handleCtrlClick(item) {
      if(this.selected.find((el) => el.id === item.id)) {
        this.selected = this.selected.filter((el) => el.id !== item.id);
      }
      else {
        this.selected.push(item);
      }

    },
    handleShiftClick(item) {
      if (this.selected.length === 1 || this.selected.length === 2) {
        const IDs = [this.selected[1]?.id ?? item.id, this.selected[0].id]    // @click.shift is triggered before v-model="selected" updates, so we need to form a list of IDs from the item.
        let start = this.current.findIndex(el => el.raw.id === Math.min(...IDs));       // getting the starting element index
        let end = this.current.findIndex(el => el.raw.id === Math.max(...IDs));       //  end element index
        if(start > end) {
          let tmp = end;
          end = start;
          start = tmp;
        }
        for (let i = start; i < end; i++) {
          if (!this.selected.find((el) => el.id === this.current[i].key)) {
            this.selected.push(this.current[i].raw);
          }
        }
      }
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?show_in_search=true&model_type=" + helpFunctions.models[this.relation_name].toLowerCase())
          .then((response) => {
            this.headers = [];
            this.response_fields = [];
            response.data.forEach((item) => {
              item.name = helpFunctions.replaceIDwithNameBaseTableInc(item.name);
              this.headers.push({
                title: this.$store.getters.translate(item.name),
                key: item.name,
                align: "start",
                sortable: true,
                value: item.name,
              });
              this.response_fields[item.name] = item.field_type;
            });
            //NO NEED TO ADD ACTIONS IN TABLE IF IT IS PREVIEW, OR IF WE CAN`T EDIT THE MODEL AND CREATE A COPY
            if(!this.preview && ((this.base_table_includable_options.copy && this.canCreateRelatedModel) || this.canEditRelatedModel)) {
              this.headers.push({
                title: this.$store.getters.translate("actions"),
                sortable: false,
                align: "center",
                value: "actions",
              });
            }
            this.getFieldsForExport();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    async getFieldsForExport() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?model_type=" + helpFunctions.models[this.relation_name].toLowerCase())
          .then((response) => {
            this.export_fields = [];
            response.data.forEach((item) => {
              item.name = helpFunctions.replaceIDwithNameBaseTableInc(item.name);
              this.export_fields[this.$store.getters.translate(item.name)] = item.name;
            });
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  computed: {
    getRecords() {
      if(this.relation_name === 'punchlistitems') {
        if(this.options.hide_inactive_punchlistitems && this.options.hide_closed_punchlistitems) {
          return this.records.filter((record) => record.active && !record.status);
        }
        else if(this.options.hide_inactive_punchlistitems) {
          return this.records.filter((record) => record.active);
        }
        else if(this.options.hide_closed_punchlistitems) {
          return this.records.filter((record) => !record.status);
        }
        else {
          return this.records;
        }
      }
      else if((this.relation_name === 'defects' && this.options.hide_closed_defects) || (this.relation_name === 'inspectionitems' && this.options.hide_closed_inspectionitems)) {
        return this.records.filter((record) => record.opened);
      }
      else if(this.records) {
        return this.records;
      }
      else {
        return [];
      }
    },
    getRecordsForExport() {
      let results = lodash.cloneDeep(this.records);
      results.forEach((item) => {
        Object.entries(item).forEach(([key, value]) => {
          if(key === 'distribution_device_function_name' || key === 'deficiency_group' || key === 'defect_description') {
            item[key] = this.$store.getters.translate(value);
          }
          else if(key === 'defect_locations' && item[key] && item[key].length > 0) {
            let defect_locations = [];
            item[key].forEach((defect_location) => {
              defect_locations.push(this.$store.getters.translate(defect_location));
            });
            item[key] = defect_locations;
          }
        });
      });
      return results;
    },
    canEditBaseModel() {
      return this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: 0 })) ||
          this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: this.parent_module_id }));
    },
    canCreateRelatedModel() {
      return this.can("create", this.$createEntity(helpFunctions.models[this.relation_name].toLowerCase(), { id: 0 }));
    },
    canDeleteRelatedModel() {
      return this.can("delete", this.$createEntity(helpFunctions.models[this.relation_name].toLowerCase(), { id: 0 }));
    },
    canEditRelatedModel() {
      return this.can("edit", this.$createEntity(helpFunctions.models[this.relation_name].toLowerCase(), { id: 0 }));
    },
    showPanel() {
      return this.can("show", this.$createEntity(helpFunctions.models[this.relation_name].toLowerCase(), { id: 0 })) ||
          //IF THE USER HAS CUSTOM PERMISSION FOR PARENT RECORD WE ALLOW HIM TO VIEW IT`S ITEMS
          (this.parent_module_id && this.can("show", this.$createEntity(this.page.model.toLowerCase(), { id: this.parent_module_id }))) ||
          //IF THE USER HAS CUSTOM PERMISSION FOR PARENT INSPECTION WE ALLOW HIM TO DO THE SAME WITH IT`S ARTICLES, ARTICLE ITEMS AND DEFECTS
          //THE CONDITION IS USED ONLY FOR DEFECTS AT "INSPECTION/EDIT" PAGE, ARTICLES AND DEFECTS AT "INSPECTION ITEM/EDIT" PAGE, ARTICLE ITEMS AT "ARTICLE/EDIT" PAGE
          (this.top_level_module_type && this.top_level_module_id && this.can("show", this.$createEntity(this.top_level_module_type, { id: this.top_level_module_id })));
    },
  },
  watch: {
    options: {
      handler() {
        this.selected = [];
        helpFunctions.setLocalStorageValue(this.relation_name + "_includable_options", this.options);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  display: flex;
}
.v-text-field >>> input {
  font-size: 12px !important;
}
.v-text-field >>> label {
  font-size: 12px !important;
}
/*FIX GREY BACKGROUND COLOR WHEN HOVERING OVER V-EXPANSION-PANEL-TITLE.*/
.v-expansion-panel {
  .v-expansion-panel-title:hover {
    color: transparent;
  }
}
</style> 