<template>
    <v-btn size="small" @click="load()">
      {{ $store.getters.translate("send_login_credentials") }}
    </v-btn>
    <BaseDraggableModal
        name="base_create_user"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.display.xsOnly"
        ref="base_create_user">
      <v-card :loading="loading" :disabled="loading">
        <BaseModal>
          <template #header>
            <v-toolbar class="draggable_selector" color="primary" dense flat>
              <v-toolbar-title>{{ $store.getters.translate("send_login_credentials") }}</v-toolbar-title>
              <v-spacer/>
              <v-btn size="small" @click="closeModal('base_create_user')">
                <v-icon icon="mdi-close" size="x-large"/>
              </v-btn>
            </v-toolbar>
          </template>
          <template #content>
            <v-card-text class="ma-5">
              <v-text-field
                  density="compact"
                  variant="underlined"
                  :disabled="loading"
                  :label="$store.getters.translate('email')"
                  v-model="record.email"
                  counter
                  required
                  validate-on="blur"
                  :rules="emailRules"/>
              <base-message :label="$store.getters.translate('message')" :modelValue="record.message"/>
            </v-card-text>
          </template>
          <template #footer>
            <v-card-actions>
              <v-spacer/>
              <v-btn :disabled="isDisabled" color="primary" variant="text" @click="save()">
                {{ $store.getters.translate("send") }}
              </v-btn>
            </v-card-actions>
          </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["user_type", "emailaddress", "person"],
  data() {
    return {
      loading: false,
      emailRules: [
        (v) => !!v || this.$store.getters.translate("please_submit_valid_email"),
        (v) => /.+@.+\..+/.test(v) || this.$store.getters.translate("please_submit_valid_email"),
        (v) => this.check(v) || this.$store.getters.translate("please_submit_valid_email"),
      ],
      record: {},
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.record = {
        email: this.emailaddress,
        person_id: this.person.id,
        user_type: this.user_type,
        message: null,
      };
      this.openModal('base_create_user');
    },
    save() {
      this.loading = true;
      this.$http
        .post(this.$store.getters.appUrl + "v2/user/create", this.record)
        .then(() => {
          this.loading = false;
          this.$toast.success(this.$store.getters.translate("the_invitation_has_been_sent"));
          this.$emit("refresh");
          this.closeModal('base_create_user');
          this.reset();
        }).catch(() => {
          this.loading = false;
          this.$toast.error(this.$store.getters.translate("email_taken_or_incorrect"));
        });
    },
    check(value) {
      let validator = require("email-validator");
      return validator.validate(value);
    },
    reset() {
      this.record = {
        email: this.emailaddress,
        person_id: this.person.id,
        user_type: this.user_type,
        message: null,
      };
    },
  },
  computed: {
    isDisabled() {
      return !(this.record.email && this.person.id && this.record.user_type);
    },
  },
};
</script>