<template>
  <v-toolbar :loading="loading" :disabled="loading" flat color="white" dense height="unset" class="mt-5">
  {{ label }}
  </v-toolbar>
  <v-list v-if="files && files.length > 0" density="compact">
    <v-list-item v-for="(file, index) in files" :key="index" color="primary">
      <v-list-item-title>
        <a @click="preview(file)">{{ file.name + ' created by ' + file.creator + ' on ' + moment(file.created_at).format('DD-MM-YYYY HH:mm') }}</a>
      </v-list-item-title>
      <template v-if="deletable" v-slot:append>
        <v-list-item-action>
          <v-icon @click="deleteFile(file)" color="red" icon="mdi-trash-can"/>
        </v-list-item-action>
      </template>
    </v-list-item>
  </v-list>
  <div class="mt-5" v-else>
    {{ $store.getters.translate("nothing_found") }}
  </div>
  <!--UPLOAD FILES-->
  <v-row v-if="create">
    <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
      <v-file-input
          v-model="new_files"
          @change="saveFiles"
          :label="$store.getters.translate('files')"
          class="ml-3 mr-3 mt-5"
          density="compact"
          placeholder="Drop file to upload"
          color="primary"
          bg-color="white"
          multiple
          show-size/>
    </v-col>
  </v-row>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["page", "parent_id", "label", "files", "deletable", "create", "category"],
  data() {
    return {
      loading: false,
      new_files: [],
    };
  },
  methods: {
    preview(file) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/preview-in-new-tab", {id: file.id})
          .then((response) => {
            this.loading = false;
            const fileUrl = response.data.url;
            window.open(fileUrl, '_blank');
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(this.$store.getters.translate(error.response?.data?.error ?? error.message));
            }
            this.loading = false;
          });
    },
    deleteFile(file) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if(this.page.model === 'document') {
            await helpFunctions.deleteFile(this.$toast, this.page.name, {document_id: this.parent_id});
            this.$emit("refresh");
            this.loading = false;
          }
          else {
            this.$http
                .delete(this.$store.getters.appUrl + "v2/files/" + file.id)
                .then(() => {
                  this.loading = false;
                  this.$toast.success(this.$store.getters.translate("successfully_deleted"));
                  this.$emit("addLogs", file); //USED AT PUNCHLIST ITEMS EDIT PAGE ONLY
                  this.$emit("refresh");
                }).catch((error) => {
                  if (this.$store.getters.isLoggedIn) {
                    this.$toast.error(this.$store.getters.translate(error.response?.data?.error ?? error.message));
                  }
                  this.loading = false;
                });
          }
        }
      });
    },
    addDropFile(e) {
      this.uploadFiles(e.dataTransfer.files);
    },
    saveFiles() {
      if (this.new_files) {
        this.uploadFiles(this.new_files);
      }
    },
    uploadFiles(files) {
      this.loading = true;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("attachment[]", files[i]);
      }
      formData.append("id", this.parent_id);
      formData.append("category", this.category);
      this.$http
          .post(this.$store.getters.appUrl + "v2/" + this.page.name + "/uploadfiles", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(() => {
            this.loading = false;
            this.$toast.success(this.$store.getters.translate("successfully_saved"));
            this.new_files = [];
            this.$emit("refresh");
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(this.$store.getters.translate(error.response?.data?.error ?? error.message));
            }
            this.loading = false;
          });
    },
  },
};
</script>