<template>
  <v-dialog v-model="isVisible">
    <div class="modal-overlay" @mousedown="startDrag" @mouseup="stopDrag">
      <div class="modal content-wrapper" :class="[...classes]" :style="modalStyle" ref="modal">     <!-- , fullscreen ? 'left-0' : '']  !-->
        <slot/>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    name: String,
    width: String,
    height: String,
    maxWidth: String,
    maxHeight: String,
    fullscreen: Boolean,
    classes: {
      type: Array,
      default: () => [],
      validator: value => Array.isArray(value),
    },
  },
  data() {
    return {
      isVisible: false,
      isDragging: false,
      startX: 0,
      startY: 0,
    };
  },
  computed: {
    modalStyle() {
      const style = {
        width: this.fullscreen ? '100vw' : this.width,
        height: this.fullscreen ? '100vh' : this.height,
        maxWidth: this.fullscreen ? 'unset' : this.maxWidth || '100%',
        maxHeight: this.fullscreen ? 'unset' : this.maxHeight,
      };
      return style;
    },
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    startDrag(e) {
      // Only start drag if the modal-header is directly interacted with
      // if (e.target === this.$refs.modal) {
      if (e.target.closest('.draggable_selector')) {
        this.isDragging = true;
        this.startX = e.clientX - this.$refs.modal.offsetLeft;
        this.startY = e.clientY - this.$refs.modal.offsetTop;

        document.addEventListener('mousemove', this.drag);
        document.addEventListener('mouseup', this.stopDrag);

        e.preventDefault(); // prevent text selection
      }
    },
    stopDrag() {
      this.isDragging = false;

      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('mouseup', this.stopDrag);
    },
    drag(e) {
      if (!this.isDragging || this.fullscreen) return;

      let newLeft = e.clientX - this.startX;
      let newTop = e.clientY - this.startY;

      const topLimit = 30;
      const bottomLimit = window.innerHeight - 30;

      newTop = Math.max(topLimit - (window.innerHeight / 2), Math.min(bottomLimit - 64 - (window.innerHeight / 2), newTop));

      this.$refs.modal.style.left = `${newLeft}px`;
      this.$refs.modal.style.top = `${newTop}px`;
    },
  },
};
</script>

<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  border-radius: 5px;
  position: absolute;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.modal::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.modal {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.content-wrapper .v-card {             /* V-CARD DOES NOT INHERIT THIS PARAMS. AD HOC FIX */
  max-height:inherit;
}
</style>