<template>
  <v-btn v-if="showPanel" size="small" @click.stop="openModal(module_name); request()">
    {{ $store.getters.translate(label) }}
    <v-icon color="primary" size="large" class="ml-2" icon="mdi-link"/>
  </v-btn>
  <BaseDraggableModal
      :name="module_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="module_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate(module_name) }}</v-toolbar-title>
            <v-spacer/>
            <v-btn :disabled="loading" size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ma-5">
            <base-tags :modelValue="tags" :modal_name="module_name" @change="searchByTags"/>
            <v-data-table
                :headers="fields"
                :items="getRecords"
                :loading="loading"
                class="elevation-1 mt-5"
                item-value="id"
                selectable-key="id"
                return-object
                show-select
                v-model="selected"
                v-bind="footer_props"
                v-model:items-per-page="options.itemsPerPage"
                v-model:page="options.page">
              <template #body.prepend>
                <tr>
                  <td/><!--EMPTY TD FOR CHECKBOXES-->
                  <td v-for="(key, index) in Object.keys(response_fields)" :key="index">
                    <v-text-field
                        v-if="key !== 'id' && key !== 'tags' && key !== 'created_at' && key !== 'updated_at'"
                        color="primary"
                        variant="underlined"
                        density="compact"
                        v-model="advanced_search[key]"
                        v-debounce:230ms.lock="showRecords"
                        @click:clear="clearSearch(key)"
                        :label="$store.getters.translate(key)"
                        single-line
                        hide-details
                        clearable/>
                  </td>
                </tr>
              </template>
              <template #body="{ items }">
                <tr v-for="item in items" :key="item.id">
                  <td>
                    <v-checkbox color="primary" v-model="selected" :value="item"/>
                  </td>
                  <td v-for="(key, index) in Object.keys(response_fields)" :key="index">
                    <!--CHECKBOXES/BOOLEAN VALUES-->
                    <template v-if="response_fields[key] === 'boolean'">
                      <v-icon v-if="item[key]" color="primary" size="large" icon="mdi-check-all"/>
                      <v-icon v-else color="red" size="large" icon="mdi-close"/>
                    </template>
                    <!--DATES-->
                    <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                      {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
                    </template>
                    <!--DROPDOWNS-->
                    <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                      <template v-if="Array.isArray(item[key])">
                        <template v-if="item[key].length > 1">
                          <v-chip style="height: auto;" class="mr-2" size="x-small" v-for="elem in item[key]" :key="elem">
                            {{ elem }}
                          </v-chip>
                        </template>
                        <template v-else>
                          {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                        </template>
                      </template>
                      <template v-else>
                        {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                      </template>
                    </template>
                    <!--LINKS-->
                    <template v-else-if="response_fields[key] === 'link'">
                      <a @click="addToSelect(item)">{{ $lodash.truncate($store.getters.translate(item[key]), { length: 30 }) }}</a>
                    </template>
                    <!--ID FIELDS-->
                    <template v-else-if="key === 'id' || key === 'internal_id'">
                      {{ item[key] }}
                    </template>
                    <!--TEXT FIELDS-->
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key]), { length: 30 }) }}
                    </template>
                  </td>
                </tr>
                <tr v-if="getRecords.length === 0">
                  <td class="text-center text-grey" :colspan="fields.length + 1">
                    <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
                    <span v-else>{{ $store.getters.translate("loading") }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions.js";
import BaseModal from "./BaseModal.vue";
import BaseDraggableModal from "./BaseDraggableModal.vue";
import {useAbility} from "@casl/vue";

export default {
  components: {BaseDraggableModal, BaseModal},
  props: ["module_name", "excluded_ids", "label"],
  setup() {
    const { can } = new useAbility();
    return { can }
  },
  data() {
    return {
      loading: false,
      records: [],
      selected: [],
      all_records: [],
      options: helpFunctions.table_options,
      footer_props: helpFunctions.footer_props,
      advanced_search: {},
      response_fields: {},
      fields: [],
      tags: [],
      selected_model: "",
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    save() {
      this.$emit("change", this.selected);
      this.reset();
    },
    request() {
      if (!this.loading) {
        this.records = [];
        this.loading = true;
        this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.module_name + "?source=site")
          .then((response) => {
            this.loading = false;
            this.all_records = response.data.data;
            this.response_fields = helpFunctions.replaceIDwithNameBaseTable(response.data.fields);
            this.fields = this.composeFields(this.response_fields);
            if (this.excluded_ids) {
              this.all_records = this.all_records.filter((record) => !this.excluded_ids.includes(record.id));
            }
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
      }
    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        response.push({
          title: this.$store.getters.translate(field_names[i]),
          key: field_names[i],
          align: "start",
          fixed: true,
          sortable: true,
          value: field_names[i],
        });
      }
      return response;
    },
    addToSelect(item) {
      if (!this.selected.includes(item)) {
        this.selected.push(item);
      }
      this.save();
    },
    showRecords() {
      var sorted_array = [];
      if (this.tags.length > 0) {
        sorted_array = helpFunctions.sortByTagsBase(this.all_records, this.tags);
      }
      else {
        sorted_array = this.all_records;
      }
      let field_names = Object.keys(this.response_fields);
      for (let i = 0; i < field_names.length; i++) {
        if (this.advanced_search[field_names[i]] && this.advanced_search[field_names[i]] !== "" && this.advanced_search[field_names[i]].length > 1) {
          sorted_array = sorted_array.filter(item => item[field_names[i]] && item[field_names[i]] !== "" && item[field_names[i]].toLowerCase().includes(this.advanced_search[field_names[i]].toLowerCase()));
          if(sorted_array.length === 0) {
            break;
          }
        }
      }
      this.records = sorted_array;
    },
    clearSearch(key) {
      this.advanced_search[key] = null;
      this.showRecords();
    },
    searchByTags(data) {
      this.tags = data;
      if (this.all_records && this.all_records.length > 0) {
        this.showRecords();
      } else {
        this.request();
      }
    },
    reset() {
      this.closeModal(this.module_name);
      this.records = [];
      this.all_records = [];
      this.selected = [];
    },
  },
  computed: {
    getRecords() {
      if(this.records) {
        return this.records;
      }
      else {
        return [];
      }
    },
    showPanel() {
      return this.can("show", this.$createEntity(helpFunctions.models[this.module_name].toLowerCase(), { id: 0 }));
    },
  },
  watch: {
    options: {
      handler() {
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.request();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  display: flex;
}
.v-text-field >>> input {
  font-size: 12px !important;
}
.v-text-field >>> label {
  font-size: 12px !important;
}
</style>