<template>
  <BaseDraggableModal
      name="article_preview"
      max-height="80vh"
      width="100vw"
      :fullscreen="true"
      ref="article_preview">
    <v-card :loading="loading" :disabled="loading" height="100%" width="100%">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('article_preview')">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ record.tag_number }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" @click="editArticle()">
              <v-icon icon="mdi-pencil" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ma-5" style="height: 98vh">
              <v-row>
                <v-col>
                  <v-row>
                    <v-col><div style="font-weight: bold;">{{ $store.getters.translate("root_asset_name").toUpperCase() }}:</div></v-col>
                    <v-col v-if="record.rootasset"><a @click="goToAsset(record.rootasset.id)">{{ $store.getters.translate(record.rootasset.name) }}</a></v-col>
                  </v-row>
                  <v-row>
                    <v-col><div style="font-weight: bold;">{{ $store.getters.translate("tag_number").toUpperCase() }}:</div></v-col>
                    <v-col><div style="font-weight: bold;">{{ record.tag_number }}</div></v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col>{{ $store.getters.translate("inspection_data").toUpperCase() }}:</v-col>
                  </v-row>
                </v-col>
                <v-col/>
                <v-col>
                  <v-row>
                    <v-col>
                      <v-layout align-center justify-center column fill-height>
                        <v-img v-if="image" :src="image" class="ma-auto article-img"/>
                        <v-img v-else src="../../../assets/images/new-logo.png" class="ma-auto article-img"/>
                      </v-layout>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider horizontal class="mt-5 mb-5"/>
              <v-row>
                <v-col>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("atex_register_data").toUpperCase() }}:</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("brand").toUpperCase() }}:</v-col>
                    <v-col v-if="record.brand" class="pt-1 pb-1">{{ record.brand.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("type").toUpperCase() }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.type }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("model").toUpperCase() }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.model }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("model_details").toUpperCase() }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.model_details }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("serial_number").toUpperCase() }}:</v-col>
                    <v-col class="pt-1 pb-1">{{ record.serial_number }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("location").toUpperCase() }}:</v-col>
                    <v-col v-if="record.location" class="pt-1 pb-1">{{ record.location.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("room").toUpperCase() }}:</v-col>
                    <v-col v-if="record.room" class="pt-1 pb-1">{{ record.room.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("application").toUpperCase() }}:</v-col>
                    <v-col v-if="record.application" class="pt-1 pb-1">{{ record.application.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("markering").toUpperCase() }}:</v-col>
                    <v-col v-if="record.markering" class="pt-1 pb-1">{{ record.markering.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("iptype").toUpperCase() }}:</v-col>
                    <v-col v-if="record.ip_type" class="pt-1 pb-1">{{ record.ip_type.name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("equipmenttype").toUpperCase() }}:</v-col>
                    <v-col v-if="record.equipment_type" class="pt-1 pb-1">{{ $store.getters.translate(record.equipment_type.name) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("inspection_institute_name").toUpperCase() }}:</v-col>
                    <v-col v-if="record.inspection_institute" class="pt-1 pb-1">{{ record.inspection_institute.full_name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("inspection_certificate_name").toUpperCase() }}:</v-col>
                    <v-col v-if="record.inspection_certificate" class="pt-1 pb-1">{{ record.inspection_certificate.full_name }}</v-col>
                  </v-row>
                  <!--LIST CERTIFICATE FILES-->
                  <v-row v-if="record.inspection_certificate && record.inspection_certificate.files.length > 0">
                    <v-col class="pt-1 pb-1">
                      <base-file-list :page="page" :parent_id="record.inspection_certificate.id" :label="$store.getters.translate('certificate_files').toUpperCase()" :files="record.inspection_certificate.files" :deletable="false" :create="false"/>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <template v-if="record.articleitems && record.articleitems.length > 0">
                    <v-row>
                      <v-col class="pt-1 pb-1">{{ $store.getters.translate("power").toUpperCase() }}:</v-col>
                      <v-col v-if="record.articleitems[0].power" class="pt-1 pb-1">{{ record.articleitems[0].power }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1 pb-1">{{ $store.getters.translate("line_voltage_name").toUpperCase() }}:</v-col>
                      <v-col v-if="record.articleitems[0].line_voltage_name" class="pt-1 pb-1">{{ record.articleitems[0].line_voltage_name }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1 pb-1">{{ $store.getters.translate("nominal_current").toUpperCase() }}:</v-col>
                      <v-col v-if="record.articleitems[0].nominal_current" class="pt-1 pb-1">{{ record.articleitems[0].nominal_current }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1 pb-1">{{ $store.getters.translate("ambient_temperature_low").toUpperCase() }}:</v-col>
                      <v-col v-if="record.articleitems[0].ambient_temperature_low" class="pt-1 pb-1">{{ record.articleitems[0].ambient_temperature_low }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1 pb-1">{{ $store.getters.translate("ambient_temperature_high").toUpperCase() }}:</v-col>
                      <v-col v-if="record.articleitems[0].ambient_temperature_high" class="pt-1 pb-1">{{ record.articleitems[0].ambient_temperature_high }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1 pb-1">{{ $store.getters.translate("general_number").toUpperCase() }}:</v-col>
                      <v-col v-if="record.articleitems[0].general_number" class="pt-1 pb-1">{{ record.articleitems[0].general_number }}</v-col>
                    </v-row>
                  </template>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  Settings
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("commissioned").toUpperCase() }}:</v-col>
                    <v-col class="pt-1 pb-1">commissioned date</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("project_workorder_number").toUpperCase() }}:</v-col>
                    <v-col class="pt-1 pb-1">some number</v-col>
                  </v-row>
                  <v-divider horizontal class="mt-5 mb-5"/>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("last_inspection").toUpperCase() }}:</v-col>
                  </v-row>
                  <template v-if="last_inspection">
                    <v-row>
                      <v-col class="pt-1 pb-1" style="font-weight: bold">{{ $store.getters.translate("gen_project_number") }}:</v-col>
                      <v-col class="pt-1 pb-1"><a @click="editInspection(last_inspection)">{{ last_inspection.gen_project_number }}</a></v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1 pb-1" style="font-weight: bold">{{ $store.getters.translate("gen_inspection_period") }}:</v-col>
                      <v-col class="pt-1 pb-1">{{ last_inspection.gen_inspection_period }}</v-col>
                    </v-row>
                  </template>
                  <v-row v-else>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("nothing_found") }}</v-col>
                  </v-row>
                  <v-divider horizontal class="mt-5 mb-5"/>
                  <v-row>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("inspections").toUpperCase() }}:</v-col>
                  </v-row>
                  <template v-if="inspections.length > 0" v-for="inspection in inspections">
                    <v-row>
                      <v-col class="pt-1 pb-1" style="font-weight: bold">{{ $store.getters.translate("gen_project_number") }}:</v-col>
                      <v-col class="pt-1 pb-1"><a @click="editInspection(inspection)">{{ inspection.gen_project_number }}</a></v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-1 pb-1" style="font-weight: bold">{{ $store.getters.translate("gen_inspection_period") }}:</v-col>
                      <v-col class="pt-1 pb-1">{{ inspection.gen_inspection_period }}</v-col>
                    </v-row>
                    <v-divider horizontal class="mt-5 mb-5"/>
                  </template>
                  <v-row v-else>
                    <v-col class="pt-1 pb-1">{{ $store.getters.translate("nothing_found") }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider horizontal class="mt-5 mb-5"/>
              <v-row>
                <v-col>
                  <base-log-field :isDisabled="true" :model_type="page.model" :model_id="record.id"/>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <v-toolbar flat color="white" dense height="unset" class="mt-5">
                    {{ $store.getters.translate("article_linked_assets").toUpperCase() }}
                  </v-toolbar>
                  <v-list v-if="record.assets && record.assets.length > 0" density="compact">
                    <v-list-item v-for="(asset, index) in record.assets" :key="index" color="primary" class="pa-0">
                      <v-list-item-title>
                        <a @click="goToAsset(asset.id)">{{ asset.name }}<small v-if="asset.parent_id"> ({{ asset.root_asset_name }})</small></a>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ moment(asset.created_at).format("DD-MM-YYYY HH:mm") }}
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                  <div class="mt-5" v-else>
                    {{ $store.getters.translate("nothing_found") }}
                  </div>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <base-file-list :page="page" :parent_id="record.id" :label="$store.getters.translate('photos').toUpperCase()" :files="record.files" :deletable="false" :create="false"/>
                </v-col>
                <v-divider vertical class="ml-5 mr-5"/>
                <v-col>
                  <base-link-list ref="base_link_list" :model_type="page.model" :model_id="record.id" :label="$store.getters.translate('links_to_other_systems').toUpperCase()" @createLink="createLink"/>
                </v-col>
              </v-row>
<!--          <v-row>
                <v-col>
                  <v-expansion-panels v-model="panel" multiple>
                    <base-table-includable v-if="record.id" ref="article_items_table" :page="page" :parent_module_id="record.id" relation_name="articleitems" :preview="true" @showFieldsSettings="showFieldsSettings"/>
                  </v-expansion-panels>
                </v-col>
              </v-row>-->
            </v-card-text>
        </template>
      </BaseModal>
      <create-external-link ref="create_external_link" :model_type="page.model" :model_id="record.id" @refresh="refreshLinkList"/>
<!--  <base-modal-fields ref="base_modal_fields" :selected_model="show_fields_model" @refresh="refreshFields()"/>-->
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
//import BaseTableIncludable from "./BaseTableIncludableData";
import moment from "moment";
import BaseLogField from "./BaseLogField";
import CreateExternalLink from "../../views/externallinks/Create";
//import BaseModalFields from "./BaseModalFields";
import BaseFileList from "./BaseFileList";
import BaseLinkList from "./BaseLinkList";

export default {
  components: {
    BaseLinkList,
    BaseFileList,
    //BaseModalFields,
    CreateExternalLink,
    BaseLogField,
    //BaseTableIncludable,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.articles,
      loading: false,
      record: {
        assets: [],
      },
      image: null,
      inspections: [],
      last_inspection: null,
      panel: [0],
      //show_fields_model: null,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(id) {
      this.record = {
        assets: [],
      };
      this.image = null;
      this.openModal('article_preview');
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toast, this.page.name, id);
      if(this.record.files && this.record.files.length > 0) {
        for (let i = 0; i < this.record.files.length; i++) {
          if (this.record.files[i].content_type === "image/png" || this.record.files[i].content_type === "image/jpeg") {
            this.$http
                .get(this.$store.getters.appUrl + "v2/files/" + this.record.files[i].id, {responseType: "blob"})
                .then((response) => {
                  const blob = new Blob([response.data], {type: this.record.files[i].content_type});
                  this.image = URL.createObjectURL(blob);
                }).catch((error) => {
                  if (this.$store.getters.isLoggedIn) {
                    this.$toast.error(error.message);
                  }
                });
            break;
          }
        }
      }
      this.inspections = [];
      if(this.record.inspectionitems && this.record.inspectionitems.length > 0) {
        this.record.inspectionitems.forEach((inspectionitem) => {
          if(inspectionitem.inspection) {
            let already_exists = this.inspections.filter(inspection => inspection.id === inspectionitem.inspection.id);
            if(already_exists.length === 0) {
              this.inspections.push(inspectionitem.inspection);
              if(this.last_inspection) {
                let last_inspection_date = "01-" + this.last_inspection.gen_inspection_period;
                let inspection_date = "01-" + inspectionitem.inspection.gen_inspection_period;
                if(moment(last_inspection_date) < moment(inspection_date)) {
                  this.last_inspection = inspectionitem.inspection;
                }
              }
              else {
                this.last_inspection = inspectionitem.inspection;
              }
            }
          }
        });
      }
      /*setTimeout(() => {
        this.updateIncludableTables();
      }, 100);*/
      this.loading = false;
    },
    /*updateIncludableTables() {
      this.$refs.article_items_table.records = this.record.articleitems;
      this.$refs.article_items_table.selected = [];
    },*/
    editArticle() {
      this.$router.push("/articles/" + this.record.id);
    },
    editInspection(item) {
      this.$router.push("/inspections/" + item.id);
    },
    goToAsset(asset_id) {
      this.$router.push({name: "Assets", params: { asset_id: asset_id }});
    },
    createLink() {
      this.$refs.create_external_link.load();
    },
    refreshLinkList() {
      this.$refs.base_link_list.load();
    }
    /*showFieldsSettings(relation_name) {
      this.show_fields_model = helpFunctions.models[relation_name].toLowerCase();
      this.$refs.base_modal_fields.load();
    },
    refreshFields() {
      if(this.show_fields_model === 'articleitem') {
        this.$refs.article_items_table.getFields();
      }
    },*/
  },
};
</script>

<style scoped>
.article-img {
  max-height: 80px;
  max-width: 80px;
}
</style>