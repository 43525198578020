<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ $store.getters.translate(module_name) }}</v-toolbar-title>
            <v-spacer/>
            <v-btn variant="text" size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ma-5">
            <v-text-field
                color="primary"
                variant="underlined"
                density="compact"
                v-model="search"
                v-debounce:250ms.lock="launchSearch"
                @click:clear="clearSearch"
                :label="$store.getters.translate('search')"
                single-line
                hide-details
                clearable
                append-inner-icon="mdi-magnify"
                class="mb-3 mt-3"/>
            <v-autocomplete
                density="compact"
                color="primary"
                bg-color="white"
                variant="underlined"
                class="ml-5"
                v-if="module_name !== 'punchlistitems' && module_name !== 'articles'"
                :label="$store.getters.translate('select_root_assets')"
                v-model="selected_root_assets"
                :items="root_assets"
                item-value="id"
                item-title="name"
                :return-object="false"
                chips
                closable-chips
                multiple
                hide-details
                clearable/>
            <base-tags :modelValue="tags" :isDisabled="module_name === 'punchlistitems'" modal_name="assets" @change="searchByTags"/>
            <template v-if="action === 'copy'">
              <v-switch density="compact" color="primary" v-model="copy_tags" :label="$store.getters.translate('copy_tags')"/>
              <v-switch density="compact" color="primary" v-model="copy_documents" :label="$store.getters.translate('copy_documents')"/>
            </template>
            <v-data-table
                v-if="module_name === 'punchlistitems' || module_name === 'articles'"
                :headers="asset_headers"
                :items="assets_array"
                :loading="loading"
                class="mt-5"
                item-value="id"
                selectable-key="id"
                show-select
                v-model="selected_assets"
                v-bind="footer_props">
            </v-data-table>
            <v-treeview
                v-else
                :items="showed_assets"
                item-value="id"
                item-title="name"
                v-model:opened="open_assets"
                v-model:selected="selected_assets"
                tile
                density="compact"
                open-strategy="multiple"
                select-strategy="independent"
                selectable/>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import lodash from "lodash";
import helpFunctions from "../../plugins/helpFunctions.js";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import { VTreeview } from "vuetify/labs/VTreeview";

export default {
  props: ["module_name", "current_assets", "modal_name", "action"],
  components: {
    BaseModal,
    BaseDraggableModal,
    VTreeview
  },
  data() {
    return {
      all_assets: [],
      all_assets_names: {},
      assets_array: [],
      assets: [],
      showed_assets: [],
      copy_tags: false,
      copy_documents: false,
      all_selected_assets: null,
      selected_assets: null,
      active_assets: null,
      open_assets: null,
      cached_open_assets: [],
      root_assets: [],
      selected_root_assets: [],
      loading: false,
      assets_limit: 10,
      id: null,
      search: "",
      tags: [],
      asset_headers: [{
        title: this.$store.getters.translate("name"),
        align: "start",
        sortable: true,
        value: "name",
      }],
      footer_props: helpFunctions.footer_props,
    };
  },
  created() {
    this.getRootAssets();
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.openModal(this.modal_name);
      this.all_assets = [];
      if (this.selected_root_assets.length > 0) {
        clearTimeout(this._timerId);
        this._timerId = setTimeout(() => {
          this.loadSelectedRootAssets();
        }, 1000);
      } else if (this.loading === false) {
        clearTimeout(this._timerId);
        this.getAllAssets();
        for (let i = 0; i < this.assets_limit; i++) {
          this.rootAssetNames(i);
        }
      }
    },
    async getRootAssets() {
      this.loading = true;
      this.root_assets = await helpFunctions.getRootAssets(this.$toast);
      this.loading = false;
    },
    async getAllAssets() {
      this.loading = true;
      this.all_assets = await helpFunctions.getAllAssets(this.$toast);
      if (this.assets_limit === 0) {
        this.loading = false;
        this.assets_limit = 10;
        this.combineIDsWithNames(this.all_assets);
        this.all_assets_names = {};
        this.all_assets = this.$lodash.sortBy(this.all_assets, "name");
        this.showRecords();
      }
    },
    async rootAssetNames(limit) {
      let start = limit * 5000;
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/additional-models", { model: "Asset", start: start })
          .then((response) => {
            this.all_assets_names = {
              ...this.all_assets_names,
              ...response.data,
            };
            this.assets_limit--;
            if (this.assets_limit === 0 && this.all_assets.length > 0) {
              this.loading = false;
              this.assets_limit = 10;
              this.combineIDsWithNames(this.all_assets);
              this.all_assets_names = {};
              this.all_assets = this.$lodash.sortBy(this.all_assets, "name");
              this.showRecords();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.response?.data?.error ?? error.message);
            }
            this.loading = false;
          });
    },
    async loadSelectedRootAssets() {
      this.loading = true;
      this.all_assets = await helpFunctions.loadSelectedRootAssets(this.$toast, this.selected_root_assets);
      this.loading = false;
      this.showRecords();
    },
    clearSearch() {
      this.open_assets = []
      this.search = "";
      this.showRecords();
      if(this.all_selected_assets) {
        this.all_selected_assets.forEach(asset => {
          if(!this.selected_assets.includes(asset)) {
            this.selected_assets.push(asset);
          }
        });
      }
      this.all_selected_assets = null;
    },
    launchSearch() {
      if (this.search && this.search.length > 2) {
        if (this.all_selected_assets == null) {
          this.all_selected_assets = lodash.cloneDeep(this.selected_assets);
        } else {
          this.selected_assets.forEach(asset => {
            if (!this.all_selected_assets.includes(asset)) {
              this.all_selected_assets.push(asset);
            }
          });
        }
        this.showRecords();
        if (this.all_selected_assets) {
          this.all_selected_assets.forEach(asset => {
            if (!this.selected_assets.includes(asset)) {
              this.selected_assets.push(asset);
            }
          });
        }
      }
      else if (this.search === '') {
        this.showRecords();
        if(this.all_selected_assets) {
          this.all_selected_assets.forEach(asset => {
            if(!this.selected_assets.includes(asset)) {
              this.selected_assets.push(asset);
            }
          });
        }
        this.all_selected_assets = null;
      }
    },
    searchByTags(data) {
      this.tags = data;
      this.showRecords();
    },
    combineIDsWithNames(items) {
      items.forEach((item) => {
        item.name = this.all_assets_names[item.id]["name"];
        if (item.children) {
          this.combineIDsWithNames(item.children);
        }
      });
    },
    createAssetsArray(assets) {
      assets.forEach(asset => {
        if(asset.children) {
          this.createAssetsArray(asset.children);
        }
        else {
          this.assets_array.push({
            id: asset.id,
            name: asset.name
          });
        }
      });
    },
    showRecords() {
      if (this.all_assets.length > 0) {
        this.assets = lodash.cloneDeep(this.all_assets);
        if (this.tags && this.tags.length > 0) {
          this.assets = helpFunctions.sortByTags(this.assets, this.tags);
        }
        if (this.search != "") {
          let response = helpFunctions.customSearch(this.assets, this.search);
          this.assets = response[0];
          this.open_assets = response[1];
        }
        this.assets = helpFunctions.customSortingAndFiltering(this.assets);
        this.assets_array = [];
        this.createAssetsArray(this.assets);
        if(this.selected_assets == null) {
          this.selected_assets = this.current_assets;
        }
        this.resetShowedAssets((!!this.search?.length) || (!!this.tags?.length));
      }
    },
    resetShowedAssets(shouldLoadAllAssets = false) {
      if(shouldLoadAllAssets) {
        this.showed_assets = lodash.cloneDeep(this.assets);
        return;
      }
      this.cached_open_assets = [];
      const initializeItem = item => {
        const newItem = { ...item };
        if (item.children) {
          if (this.open_assets?.includes(item.id)) {
            this.cached_open_assets.push(item.id);
            newItem.children = item.children.map(initializeItem);
          } else {
            newItem.children = [];
          }
        }
        return newItem;
      }
      this.showed_assets = this.assets.map(initializeItem);
    },
    updateShowedAssets(newlyOpened) {
      const item = helpFunctions.findItemById(this.showed_assets, newlyOpened[0]);
      if (item && item.children?.length === 0) {
        this.loadChildren(item);
      }
    },
    loadChildren(item) {
      return new Promise((resolve) => {
        const assetItem = helpFunctions.findItemById(this.assets, item.id);
        if (assetItem && assetItem.children) {
          item.children = assetItem.children.map(child => ({
            ...child,
            children: child.children ? [] : undefined
          }));
        }
        resolve();
      });
    },
    save() {
      if(this.action === 'copy') {
        this.$emit("refresh", { ids: this.selected_assets, copy_tags: this.copy_tags, copy_documents: this.copy_documents });
      }
      else {
        if(this.all_selected_assets) {
          this.all_selected_assets.forEach(asset => {
            if(!this.selected_assets.includes(asset)) {
              this.selected_assets.push(asset);
            }
          });
        }
        this.all_selected_assets = null;
        this.$emit("refresh", this.selected_assets);
      }
      this.reset();
    },
    reset() {
      this.all_assets = [];
      this.showed_assets = [];
      this.all_assets_names = {};
      this.assets = [];
      this.copy_tags = false;
      this.copy_documents = false;
      this.all_selected_assets = null;
      this.selected_assets = null;
      this.active_assets = null;
      this.open_assets = null;
      this.root_assets = [];
      this.loading = false;
      this.assets_limit = 10;
      this.search = "";
      this.closeModal(this.modal_name);
    },
  },
  watch: {
    open_assets: {
      handler(newOpenAssets, oldOpenAssets) {
        const newlyOpened = newOpenAssets?.filter(id => !oldOpenAssets?.includes(id)).filter(id => !this.cached_open_assets.includes(id));
        if(newlyOpened && newlyOpened.length) {
          this.updateShowedAssets(newlyOpened);
          if (!this.cached_open_assets.includes(newlyOpened[0])) {
            this.cached_open_assets.push(newlyOpened[0]);
          }
        }
      },
      deep: true,
    },
    selected_root_assets: {
      handler() {
        this.load();
      },
    },
    selected_assets: {
      handler() {
        if(this.action === "move" && this.selected_assets && this.selected_assets.length > 1) {
          this.$toast.error(this.$store.getters.translate("only_one_asset_selection"));
          this.selected_assets.pop();
        }
      },
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  display: flex;
}
.v-text-field >>> input {
  font-size: 12px !important;
}
.v-text-field >>> label {
  font-size: 12px !important;
}
</style>