<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar v-if="!modal" flat color="white" dense>
          <v-autocomplete
              density="compact"
              color="primary"
              bg-color="white"
              variant="underlined"
              class="ml-5 mt-2"
              :label="$store.getters.translate('model_type')"
              v-model="selected_model"
              :return-object="false"
              item-value="value"
              item-title="text"
              :items="$lodash.sortBy(models, 'text')"/>
          <!--RIGHT PART START (BUTTONS)-->
          <v-spacer/>
          <v-switch v-if="$store.getters.getUser.superuser" color="primary" class="mt-5" :label="$store.getters.translate('apply_for_new_users')" v-model="apply_new_users" @click.stop=""/>
          <v-spacer/>
          <!--CREATE CUSTOM FIELD ON FIELDS PAGE-->
          <v-btn :disabled="loading" v-if="canCreate && (selected_model === 'Company' || selected_model === 'Contact' || selected_model === 'Document' ||
                 selected_model === 'Employee' || selected_model === 'Project' || selected_model === 'Inspection' || selected_model === 'InspectionItem' ||
                 selected_model === 'Defect' || selected_model === 'Article' || selected_model === 'ArticleItem')" @click="$emit('create', selected_model)" size="small">
              {{ $store.getters.translate("create") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
          </v-btn>
          <!--SAVE-->
          <v-btn v-if="canEdit" :disabled="loading" @click="save()" size="small">
            {{ $store.getters.translate("save") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-content-save"/>
          </v-btn>
        </v-toolbar>
        <v-data-table
            id="base_table_fields"
            :headers="fields"
            :items="getRecords"
            :loading="loading"
            class="elevation-1"
            :show-select="false"
            :items-per-page="100">
          <template #body="{ items }">
            <draggable :disabled="!canEdit" :list="items" tag="tbody" @end="endDrag(items)" item-key="id">
              <template #item="{ element:field }">
                <tr>
                  <td><v-icon color="primary" size="small" class="page__grab-icon" icon="mdi-arrow-all"/></td>
                  <td>{{ field.sort_order }}</td>
                  <td>{{ field.id }}</td>
                  <td><a @click="$emit('edit', field)">{{ $store.getters.translate(field.name) }}</a></td>
                  <td>{{ $store.getters.translate(field.field_type) }}</td>
                  <td v-if="$store.getters.getUser.superuser"><v-checkbox color="primary" v-model="field.mandatory"/></td>
                  <td><v-checkbox :disabled="!canEdit" color="primary" v-model="field.show_in_search"/></td>
                  <td v-if="$store.getters.getUser.superuser"><v-checkbox color="primary" v-model="field.show_on_creation"/></td>
                  <td v-if="$store.getters.getUser.superuser"><v-checkbox color="primary" v-model="field.show_on_update"/></td>
                  <!--ACTION BUTTONS-->
                  <td v-if="!modal && (canEdit || canDelete)" style="text-align: right">
                    <v-icon v-if="canEdit" @click="$emit('edit', field)" color="primary" size="small" class="mr-2" icon="mdi-pencil"/>
                    <v-icon v-if="canDelete && field.custom_field == 1" @click="deleteRecord(field.id)" color="red" size="small" class="mr-2" icon="mdi-trash-can"/>
                  </td>
                </tr>
              </template>
            </draggable>
          </template>
          <template #bottom/>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import helpFunctions from "../../plugins/helpFunctions.js";
import { useAbility } from '@casl/vue';

export default {
  components: { draggable },
  props: ["page", "current_model", "modal"],
  setup() {
    const { can } = useAbility()
    return { can }
  },
  data() {
    return {
      loading: false,
      all_records: [],
      records: [],
      fields: [],
      models: [],
      selected_model: "",
      apply_new_users: false,
    };
  },
  beforeMount() {
    document.querySelector('#base_table_fields > .v-table__wrapper > table > tbody')?.remove();
  },
  mounted() {
    const sortableTbody = document.querySelector('#base_table_fields > .v-table__wrapper > table > tbody > tbody');
    // <draggable> ruin view in <tbody> (create a <div> tag inside <tbody>)
    //FIX: https://github.com/SortableJS/Vue.Draggable/issues/1212#issuecomment-1918880591
    if (sortableTbody) {
      const tbody = document.querySelector('#base_table_fields > .v-table__wrapper > table > tbody')
      tbody.parentNode.append(sortableTbody);
      tbody.remove()
    }
  },
  created() {
    this.load();
  },
  methods: {
    load() {
      this.records = [];
      this.all_records = [];
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site")
          .then((response) => {
            this.loading = false;
            this.all_records = response.data.data;
            this.fields = this.composeFields(helpFunctions.replaceIDwithNameBaseTable(response.data.fields));
            this.fillModelsDropdown();
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        if(this.$store.getters.getUser.superuser || (field_names[i] !== 'mandatory' && field_names[i] !== 'show_on_creation' && field_names[i] !== 'show_on_update')) {
          response.push({
            title: this.$store.getters.translate(field_names[i]),
            align: "start",
            fixed: true,
            sortable: false,
          });
        }
      }
      if(!this.modal && (this.canEdit || this.canDelete)) {
        response.push({
          title: this.$store.getters.translate("actions"),
          align: "end",
          fixed: true,
          sortable: false,
        });
      }
      return response;
    },
    fillModelsDropdown() {
      if(this.modal) {
        this.selected_model = this.current_model;
      }
      else {
        if (this.$store.getters.getUser.superuser) {
          this.models = helpFunctions.models_for_fields_table;
        }
        else {
          this.models = [];
          helpFunctions.models_for_fields_table.forEach((model) => {
            if (this.can("show", this.$createEntity(model.value.toLowerCase(), { id: 0 }))) {
              this.models.push(model);
            }
          });
        }
        if (this.models.length > 0) {
          this.selected_model = this.models[0].value;
        }
      }
    },
    showRecords() {
      this.records = [];
      for (let i = 0; i < this.all_records.length; i++) {
        if (this.all_records[i].model_type.toLowerCase() === this.selected_model.toLowerCase()) {
          this.records.push(this.all_records[i]);
        }
      }
    },
    save() {
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        this.fieldDataChanged(this.records[i]);
      }
      this.$toast.success(this.$store.getters.translate("successfully_saved"));
    },
    endDrag(items) {
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        for (let z = 0; z < items.length; z++) {
          if (temp_records[i].id === items[z].id && temp_records[i].sort_order !== z + 1) {
            this.records[i].sort_order = z + 1;
            //NO NEED TO SAVE CHANGES IMMEDIATELY
            //this.fieldDataChanged(this.records[i]);
          }
        }
      }
      this.records.sort((a, b) => a.sort_order - b.sort_order); // The table was not re-sorted in the previous algorithm, although the values were changed correctly.
    },
    fieldDataChanged(field) {
      this.loading = true;
      field.apply_new_users = this.apply_new_users;
      this.$http
          .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + field.id, field)
          .then(() => {
            this.loading = false;
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then((res) => {
        if (res) {
          this.deleteAction(id);
        }
      });
    },
    async deleteAction(id) {
      this.loading = true;
      this.$http
          .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
          .then(() => {
            this.loading = false;
            this.load();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
  },
  computed: {
    getRecords() {
      if(this.records) {
        return this.records;
      }
      else {
        return [];
      }
    },
    canCreate() {
      return this.can("create", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
    canEdit() {
      return this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
    canDelete() {
      return this.can("delete", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
  },
  watch: {
    selected_model: {
      handler() {
        this.showRecords();
      },
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  display: flex;
}
.v-text-field >>> input {
  font-size: 12px !important;
}
.v-text-field >>> label {
  font-size: 12px !important;
}
</style>