<template>
  <BaseDraggableModal
      name="project_management_answers"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="project_management_answers">
    <v-card>
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text>
            <base-table-project-management-answers ref="base_table_project_management_answers" :categories="categories" :subcategories="subcategories" :current_category="current_category" :answers="answers" @edit="editAnswer" @refresh="$emit('refresh')"/>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import BaseTableProjectManagementAnswers from "./BaseTableProjectManagementAnswers";

export default {
  props: ["categories", "subcategories", "current_category", "answers"],
  components: {
    BaseTableProjectManagementAnswers,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.projectmanagementanswers,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.openModal('project_management_answers');
    },
    save() {
      this.$refs.base_table_project_management_answers.save();
    },
    editAnswer(answer) {
      this.$emit('edit', answer);
    },
    reset() {
      this.$emit('refresh');
      this.closeModal('project_management_answers')
    },
  },
};
</script>