<template>
  <BaseDraggableModal
      name="edit_project_management_answer"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      ref="edit_project_management_answer">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="closeModal('edit_project_management_answer')">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn :disabled="loading" size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
          <v-card-text class="ma-5">
            <v-text-field
                density="compact"
                variant="underlined"
                color="primary"
                :label="$store.getters.translate('name')"
                v-model="record.name">
                <template #label>{{ $store.getters.translate('name') }}<span class="text-red"><strong>*</strong></span></template>
              </v-text-field>
            <v-autocomplete
                density="compact"
                color="primary"
                bg-color="white"
                variant="underlined"
                :label="$store.getters.translate('category')"
                v-model="record.category"
                :return-object="false"
                :items="categories">
                <template #label>{{ $store.getters.translate('category') }}<span class="text-red"><strong>*</strong></span></template>
              </v-autocomplete>
            <v-autocomplete
                density="compact"
                color="primary"
                bg-color="white"
                variant="underlined"
                :label="$store.getters.translate('subcategory')"
                v-model="record.subcategory"
                :return-object="false"
                :items="subcategories[record.category]">
              <template #label>{{ $store.getters.translate('subcategory') }}<span class="text-red"><strong>*</strong></span></template>
            </v-autocomplete>
          </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";

export default {
  props: ["categories", "subcategories"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: helpFunctions.pages.projectmanagementanswers,
      loading: false,
      record: {},
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load(answer) {
      this.record = this.$lodash.cloneDeep(answer);
      this.openModal('edit_project_management_answer');
    },
    async save() {
      if(!this.loading) {
        let usual_fields_required = [{name: 'name', mandatory: true}, {name: 'category', mandatory: true}, {name: 'subcategory', mandatory: true}];
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, usual_fields_required)) {
          this.loading = true;
          if (await helpFunctions.saveModel(this.$toast, this.page.name, this.record)) {
            this.$emit("refresh");
            this.closeModal('edit_project_management_answer');
          }
          this.loading = false;
        }
      }
    },
  },
  watch: {
    'record.category': {
      handler() {
        this.record.subcategory = this.subcategories[this.record.category][0];
      },
    },
  },
};
</script>