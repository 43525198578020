<template>
  <div class="wrapper">
    <div class="header">
      <slot name="header"/>
    </div>
    <div class="content">
      <slot name="content"/>
    </div>
    <div class="footer">
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal'
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  height: inherit; /* Full height of the modal container */
  max-height: inherit;
}
.header {
  flex-shrink: 0;
  left: 0;
  right:0;
  z-index: 1050;
}
.content {
  flex-grow: 1;
  overflow-y: auto;
  -ms-overflow-style: scrollbar; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
  max-height: inherit;
}
.content::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}
</style>