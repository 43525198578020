<template>
  <BaseDraggableModal
      name="meetings"
      max-height="80vh"
      width="100vw"
      :fullscreen="true"
      ref="meetings">
    <v-card :loading="loading" :disabled="loading" height="100%" width="100%">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text style="height: 98vh">
              <v-data-table
                  :headers="headers"
                  :items="getRecords"
                  :loading="loading"
                  v-bind="footer_props"
                  v-model:items-per-page="options.itemsPerPage"
                  v-model:page="options.page"
                  :show-select="false">
                <template #body.prepend>
                  <tr>
                    <td v-for="(key, index) in Object.keys(response_fields)" :key="index">
                      <v-text-field
                          color="primary"
                          variant="underlined"
                          density="compact"
                          class="mb-2"
                          v-if="response_fields[key] === 'text_field'"
                          v-model="advanced_search[key]"
                          v-debounce:230ms.lock="showRecords"
                          @click:clear="clearSearch(key)"
                          :label="(key === 'this_week_log_text' || key === 'last_week_log_text') ? $store.getters.translate('search') : $store.getters.translate(key)"
                          single-line
                          hide-details
                          clearable/>
                      <v-autocomplete
                          color="primary"
                          bg-color="white"
                          variant="underlined"
                          v-else-if="response_fields[key] === 'select_single'"
                          v-model="advanced_search[key]"
                          :label="$store.getters.translate(key)"
                          :return-object="false"
                          clearable
                          :items="advanced_search_models[key]"
                          @update:model-value="showRecords"/>
                    </td>
                  </tr>
                </template>
                <template #body="{ items }">
                  <tr v-for="item in items" :key="item.id">
                    <td>{{item.name}}</td>
                    <td>{{item.root_asset_name}}</td>
                    <td>{{item.cluster}}</td>
                    <td>{{item.description}}</td>
                    <td>{{item.project_phase}}</td>
                    <td>{{item.project_engineer_name}}</td>
                    <td>
                      <textarea :disabled="true" :style="{ height: canEditMeetingLog(item) ? '86px' : '120px' }" v-model="item.this_week_log_text"/>
                      <v-text-field
                          class="custom-text-field"
                          variant="underlined"
                          color="primary"
                          v-if="canEditMeetingLog(item)"
                          :placeholder="$store.getters.translate('add_to_log')"
                          single-line
                          density="compact"
                          v-model="new_logs[item.id]"
                          hide-details>
                        <template #append>
                          <v-icon @click="insertContent(item)" icon="mdi-plus"/>
                        </template>
                      </v-text-field>
                    </td>
                    <td>
                      <textarea :disabled="true" style="height: 120px" v-model="item.last_week_log_text"/>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";
import {useAbility} from "@casl/vue";
import Pusher from 'pusher-js';
import moment from "moment";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  setup() {
    const { can } = new useAbility();
    return { can }
  },
  data() {
    return {
      page: helpFunctions.pages.meetings,
      loading: false,
      all_records: [],
      records: [],
      options: helpFunctions.table_options,
      advanced_search: {},
      advanced_search_models: {
        root_asset_name: [],
        cluster: [],
        project_phase: ["Identify", "Appraise", "Concept Select", "Define", "Detailed Design", "Construct", "Close Out"],
        project_engineer_name: []
      },
      new_logs: [],
      employee_id: null,
      headers: [
        { title: this.$store.getters.translate("name"), sortable: true, value: "name" },
        { title: this.$store.getters.translate("root_asset_name"), sortable: true, value: "root_asset_name" },
        { title: this.$store.getters.translate("cluster"), sortable: true, value: "cluster" },
        { title: this.$store.getters.translate("description"), value: "description" },
        { title: this.$store.getters.translate("project_phase"), sortable: true, value: "project_phase" },
        { title: this.$store.getters.translate("project_engineer_name"), sortable: true, value: "project_engineer_name" },
        { title: "This week: " + moment().startOf('week').format('DD-MM-YYYY') + " - " + moment().endOf('week').format('DD-MM-YYYY'), value: "this_week_log_text", width: "25%" },
        { title: "Last week: " + moment().subtract(1, 'weeks').startOf('week').format('DD-MM-YYYY') + " - " + moment().subtract(1, 'weeks').endOf('week').format('DD-MM-YYYY'), value: "last_week_log_text", width: "25%" },
      ],
      response_fields: {
        name: "text_field",
        root_asset_name: "select_single",
        cluster: "select_single",
        description: "text_field",
        project_phase: "select_single",
        project_engineer_name: "select_single",
        this_week_log_text: "text_field",
        last_week_log_text: "text_field",
      },
      footer_props: helpFunctions.footer_props,
      pusher: null
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load() {
      this.records = [];
      this.all_records = [];
      this.employee_id = this.$store.state.user.person.employee.id;
      this.openModal('meetings');
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site")
          .then((response) => {
            this.loading = false;
            this.all_records = response.data;
            this.all_records.forEach(record => {
              if(!this.advanced_search_models.root_asset_name.includes(record.root_asset_name)) {
                this.advanced_search_models.root_asset_name.push(record.root_asset_name);
              }
              if(!this.advanced_search_models.cluster.includes(record.cluster)) {
                this.advanced_search_models.cluster.push(record.cluster);
              }
              if(!this.advanced_search_models.project_engineer_name.includes(record.project_engineer_name)) {
                this.advanced_search_models.project_engineer_name.push(record.project_engineer_name);
              }
            });
            this.showRecords();
            if(!this.pusher) {
              this.registerPusher();
            }
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
      });
    },
    showRecords() {
      let sorted_array = this.all_records;
      let field_names = Object.keys(this.response_fields);
      for (let i = 0; i < field_names.length; i++) {
        if (this.advanced_search[field_names[i]] && this.advanced_search[field_names[i]] !== "" && this.advanced_search[field_names[i]].length > 1) {
          sorted_array = sorted_array.filter(item => item[field_names[i]] && item[field_names[i]] !== "" && item[field_names[i]].toLowerCase().includes(this.advanced_search[field_names[i]].toLowerCase()));
          if(sorted_array.length === 0) {
            break;
          }
        }
      }
      this.records = sorted_array;
    },
    clearSearch(key) {
      this.advanced_search[key] = null;
      this.showRecords();
    },
    insertContent(item) {
      if(this.new_logs[item.id]) {
        let new_message_signed = "[" + moment().format("DD-MM-YYYY HH:mm:ss") + " - " + this.$store.getters.getUser.person.casual_name + "] " + this.new_logs[item.id];
        if (!item.this_week_log_text) {
          item.this_week_log_text = new_message_signed;
        } else {
          item.this_week_log_text = new_message_signed + "\n" + item.this_week_log_text;
        }
        this.new_logs[item.id] = null;

        this.$http
            .post(this.$store.getters.appUrl + "v2/" + this.page.name, {project_management_id: item.id, log: new_message_signed})
            .catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.response?.data?.error ?? error.message);
              }
              this.loading = false;
            });
      }
    },
    registerPusher() {
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
        encrypted: true,
      });
      Pusher.logToConsole = true;
      this.pusher.connection.bind(('connected'), () => {
        this.$http.defaults.headers.common['X-Socket-Id'] = this.pusher.connection.socket_id;
      })
      this.pusher.subscribe("meetingsUpdated");
      this.pusher.bind('meetings-updated', (data) => {
        if(data.response.sender_id !== this.$store.state.user.id) {
          this.load();
        }
      });
    },
    reset() {
      this.pusher.unsubscribe("meetingsUpdated");
      this.pusher = null;
      this.closeModal('meetings');
    },
    canEditMeetingLog(item) {
      return this.employee_id === item.project_engineer_id || this.can("show", this.$createEntity("meeting", { id: 0 }));
    },
  },
  computed: {
    getRecords() {
      if(this.records) {
        return this.records;
      }
      else {
        return [];
      }
    },
  },
  watch: {
    advanced_search: {
      handler() {
        this.options.page = 1;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
textarea {
  padding: 5px;
  border: 1px solid #9E9E9E;
  border-radius: 5px;
  width: 100%;
  font-size: 12px !important;
  margin-bottom: -5px !important;
  resize: none;
}

textarea:focus {
  outline: none !important;
}

input {
  padding: 0 !important;
}

.custom-text-field {
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid #9E9E9E;
  border-radius: 5px;
  width: 100%;
  font-size: 12px !important;
}
</style>