<template>
  <v-row>
    <v-col>
      <v-card class="mt-2" :loading="loading" :disabled="loading">
        <v-toolbar flat color="white" dense>
          <v-autocomplete
              density="compact"
              color="primary"
              bg-color="white"
              variant="underlined"
              class="ml-5 mt-2"
              :label="$store.getters.translate('category')"
              v-model="selected_category"
              :return-object="false"
              :items="categories"/>
          <v-autocomplete
              density="compact"
              color="primary"
              bg-color="white"
              variant="underlined"
              class="ml-5 mt-2"
              :label="$store.getters.translate('subcategory')"
              v-model="selected_subcategory"
              :return-object="false"
              :items="subcategories[selected_category]"/>
          <!--RIGHT PART START (BUTTONS)-->
          <v-spacer/>
          <!--CREATE PROJECT MANAGEMENT QUESTION-->
          <v-btn v-if="canCreate" :disabled="loading" @click="$emit('create', [selected_category, selected_subcategory])" size="small">
              {{ $store.getters.translate("create") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-plus"/>
          </v-btn>
          <!--SAVE-->
          <v-btn v-if="canEdit" :disabled="loading" @click="save()" size="small">
            {{ $store.getters.translate("save") }}
            <v-icon color="primary" size="large" class="ml-2" icon="mdi-content-save"/>
          </v-btn>
        </v-toolbar>
        <v-data-table
            id="base_table_project_management_questions"
            :headers="fields"
            :items="getRecords"
            :loading="loading"
            class="elevation-1"
            :show-select="false"
            :items-per-page="100">
          <template #body="{ items }">
            <draggable :disabled="!canEdit" :list="items" tag="tbody" @end="endDrag(items)" item-key="id">
              <template #item="{ element:question }">
                <tr>
                  <td><v-icon color="primary" size="small" class="page__grab-icon" icon="mdi-arrow-all"/></td>
                  <td>{{ question.sort_order }}</td>
                  <td><a @click="$emit('edit', question)">{{ $store.getters.translate(question.name) }}</a></td>
                  <td>{{ $store.getters.translate(question.category) }}</td>
                  <td>{{ $store.getters.translate(question.subcategory) }}</td>
                  <!--ACTION BUTTONS-->
                  <td v-if="canEdit || canDelete" style="text-align: right">
                    <v-icon v-if="canEdit" @click="$emit('edit', question)" color="primary" size="small" class="mr-2" icon="mdi-pencil"/>
                    <v-icon v-if="canDelete" @click="deleteRecord(question.id)" color="red" size="small" class="mr-2" icon="mdi-trash-can"/>
                  </td>
                </tr>
              </template>
            </draggable>
          </template>
          <template #bottom/>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import draggable from "vuedraggable";
import helpFunctions from "../../plugins/helpFunctions.js";
import { useAbility } from '@casl/vue';

export default {
  components: { draggable },
  setup() {
    const { can } = useAbility()
    return { can }
  },
  data() {
    return {
      page: helpFunctions.pages.projectmanagementquestions,
      loading: false,
      all_records: [],
      records: [],
      fields: helpFunctions.project_management_questions_and_answers,
      categories: [],
      subcategories: [],
      selected_category: "Project Engineer",
      selected_subcategory: "Initiate phase (CP0+CP1)",
    };
  },
  beforeMount() {
    document.querySelector('#base_table_project_management_questions > .v-table__wrapper > table > tbody')?.remove();
  },
  mounted() {
    const sortableTbody = document.querySelector('#base_table_project_management_questions > .v-table__wrapper > table > tbody > tbody');
    // <draggable> ruin view in <tbody> (create a <div> tag inside <tbody>)
    //FIX: https://github.com/SortableJS/Vue.Draggable/issues/1212#issuecomment-1918880591
    if (sortableTbody) {
      const tbody = document.querySelector('#base_table_project_management_questions > .v-table__wrapper > table > tbody')
      tbody.parentNode.append(sortableTbody);
      tbody.remove()
    }
  },
  created() {
    if(this.canEdit || this.canDelete) {
      this.fields.push({title: this.$store.getters.translate("actions"), align: "end", fixed: true, sortable: false });
    }
    this.load();
  },
  methods: {
    async load() {
      this.records = [];
      this.all_records = [];
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site")
          .then((response) => {
            this.loading = false;
            this.all_records = this.$lodash.sortBy(response.data.data, 'sort_order');
            this.all_records.forEach((question) => {
              if(!this.categories.includes(question.category)) {
                this.categories.push(question.category);
              }
              if(!this.subcategories[question.category]) {
                this.subcategories[question.category] = [];
              }
              if(!this.subcategories[question.category].includes(question.subcategory)) {
                this.subcategories[question.category].push(question.subcategory);
              }
            });
            this.showRecords();
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toast.error(error.message);
            }
            this.loading = false;
          });
    },
    showRecords() {
      this.records = [];
      for (let i = 0; i < this.all_records.length; i++) {
        if (this.all_records[i].category.toLowerCase() === this.selected_category.toLowerCase() &&
            this.all_records[i].subcategory.toLowerCase() === this.selected_subcategory.toLowerCase()) {
          this.records.push(this.all_records[i]);
        }
      }
    },
    save() {
      this.loading = true;
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        helpFunctions.saveModelSilently(this.page.name, this.records[i]);
      }
      this.loading = false;
      this.$toast.success(this.$store.getters.translate("successfully_saved"));
    },
    endDrag(items) {
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        for (let z = 0; z < items.length; z++) {
          if (temp_records[i].id === items[z].id && temp_records[i].sort_order !== z + 1) {
            this.records[i].sort_order = z + 1;
          }
        }
      }
      this.records.sort((a, b) => a.sort_order - b.sort_order); // The table was not re-sorted in the previous algorithm, although the values were changed correctly.
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toast, this.page.name, id)) {
            await this.load();
          }
          this.loading = false;
        }
      });
    },
  },
  computed: {
    getRecords() {
      if(this.records) {
        return this.records;
      }
      else {
        return [];
      }
    },
    canCreate() {
      return this.can("create", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
    canEdit() {
      return this.can("edit", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
    canDelete() {
      return this.can("delete", this.$createEntity(this.page.model.toLowerCase(), { id: 0 }));
    },
  },
  watch: {
    selected_category: {
      handler() {
        this.selected_subcategory = this.subcategories[this.selected_category][0];
        this.showRecords();
      },
    },
    selected_subcategory: {
      handler() {
        this.showRecords();
      },
    },
  },
};
</script>

<style scoped>
.v-checkbox {
  display: flex;
}
.v-text-field >>> input {
  font-size: 12px !important;
}
.v-text-field >>> label {
  font-size: 12px !important;
}
</style>